// vim: ft=javascript:
const backFirstPart = 'XXXXXX'
const backFullUrl = ''
let _backUrl
if (backFullUrl) {
  if (!backFullUrl.endsWith('/')) {
    _backUrl = `${backFullUrl}/`
  } else {
    _backUrl = backFullUrl
  }
} else {
  const schema = window.location.protocol
  const hostname = window.location.hostname
  const [, ...lastParts] = hostname.split('.')
  _backUrl = `${schema}//${backFirstPart}.${lastParts.join('.')}/`
}
export const backUrl = _backUrl
export const graphqlUrl = `${backUrl}graphql_ng/`
export const graphqlPublicUrl = `${backUrl}public/`
export const fakeUser = false
